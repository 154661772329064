<template>
  <div>
    <v-btn fab rounded :color="colors.danger" v-if="currentUser.isBroadcaster" @click="endMeeting"
           class="endMeetingBtn white--text">
      <v-icon>mdi-exit-to-app</v-icon>
    </v-btn>
    <v-btn v-if="(!isMeet) || (isMeet && currentUser.isBroadcaster)" fab rounded :color="colors.success" @click="openSetting" class="openSettingBtn white--text"
           :style="{left: currentUser.isBroadcaster ? '85px' : '20px'}">
      <v-icon>mdi-cog</v-icon>
    </v-btn>

    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar
            dark
            :color="colors.success"
        >
          <v-btn
              icon
              dark
              @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title :class="[ltrMode ? 'ltr-font' : 'rtl-font']">{{ $t('meeting.setting') }}</v-toolbar-title>
        </v-toolbar>

        <v-stepper
            v-model="step"
            vertical
            :class="[ltrMode ? 'ltr-font' : 'rtl-font']"
        >
          <v-stepper-step v-if="currentUser.isBroadcaster" step="1" complete @click="step = 1"
                          complete-icon="mdi-cog-outline" :color="colors.success">
            {{ $t('meeting.manage-users-title') }}
            <small>{{ $t('meeting.manage-users-description') }}</small>
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-data-table
                align="center"
                :headers="permissionsHeader"
                :items="permissions"
                class="elevation-5 bordered-table"
                :items-per-page="15"
                hide-default-footer
            >
              <template v-slot:item.microphone="{item}">
                <v-btn :color="item.microphone ? colors.success : colors.danger" x-small fab
                       class="white--text" @click="item.microphone = !item.microphone">
                  <v-icon v-if="item.microphone">mdi-check</v-icon>
                  <v-icon v-else>mdi-close</v-icon>
                </v-btn>
              </template>

              <template v-slot:item.webcam="{item}">
                <v-btn :color="item.webcam ? colors.success : colors.danger" x-small fab
                       class="white--text" @click="item.webcam = !item.webcam">
                  <v-icon v-if="item.webcam">mdi-check</v-icon>
                  <v-icon v-else>mdi-close</v-icon>
                </v-btn>
              </template>

              <template v-slot:item.screen="{item}">
                <v-btn :color="item.screen ? colors.success : colors.danger" x-small fab
                       class="white--text" @click="item.screen = !item.screen">
                  <v-icon v-if="item.screen">mdi-check</v-icon>
                  <v-icon v-else>mdi-close</v-icon>
                </v-btn>
              </template>

              <template v-slot:item.whiteboard="{item}">
                <v-btn :color="item.whiteboard ? colors.success : colors.danger" x-small fab
                       class="white--text" @click="item.whiteboard = !item.whiteboard">
                  <v-icon v-if="item.whiteboard">mdi-check</v-icon>
                  <v-icon v-else>mdi-close</v-icon>
                </v-btn>
              </template>

              <template v-slot:item.video="{item}">
                <v-btn :color="item.video ? colors.success : colors.danger" x-small fab
                       class="white--text" @click="item.video = !item.video">
                  <v-icon v-if="item.video">mdi-check</v-icon>
                  <v-icon v-else>mdi-close</v-icon>
                </v-btn>
              </template>

              <template v-slot:item.audio="{item}">
                <v-btn :color="item.audio ? colors.success : colors.danger" x-small fab
                       class="white--text" @click="item.audio = !item.audio">
                  <v-icon v-if="item.audio">mdi-check</v-icon>
                  <v-icon v-else>mdi-close</v-icon>
                </v-btn>
              </template>

              <template v-slot:item.record="{item}">
                <v-btn :color="item.record ? colors.success : colors.danger" x-small fab
                       class="white--text" @click="item.record = !item.record">
                  <v-icon v-if="item.record">mdi-check</v-icon>
                  <v-icon v-else>mdi-close</v-icon>
                </v-btn>
              </template>

            </v-data-table>
            <v-btn :color="colors.success" rounded class="white--text mb-4 mt-4" @click="saveUserPermissions">
              {{ $t('meeting.save') }}
            </v-btn>
            <v-btn :color="colors.danger" rounded class="white--text mb-4 mt-4" :class="[ltrMode ? 'ml-2' : 'mr-2']"
                   @click="getMeetingInfo('permissions')">{{ $t('meeting.reset') }}
            </v-btn>
          </v-stepper-content>

          <v-stepper-step v-if="!isMeet" :color="colors.success" step="2" complete @click="step = 2; getTasks()" complete-icon="mdi-cog-outline">
            {{ $t('meeting.tasks') }}
          </v-stepper-step>
          <v-stepper-content step="2">
            <tasks
                :ltrMode="ltrMode"
                :tasks="tasks"
                :users="users"
                :clas="clas"
                :meetingId="meetingId"
                :currentUser="currentUser"
                :colors="colors">
            </tasks>
          </v-stepper-content>

          <v-stepper-step v-if="!isMeet && currentUser.isBroadcaster" step="3" complete @click="step = 3" complete-icon="mdi-cog-outline" :color="colors.success">
            {{ $t('meeting.users-attendances') }}
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-data-table
                align="center"
                :headers="attendancesHeader"
                :items="attendances"
                class="elevation-5 bordered-table"
                :items-per-page="15"
                hide-default-footer
            >
              <template v-slot:item.isAttended="{item}">
                <v-btn :color="item.persian_join_time ? colors.success : colors.danger" x-small fab class="white--text">
                  <v-icon v-if="item.persian_join_time">mdi-check</v-icon>
                  <v-icon v-else>mdi-close</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.join_time="{item}">
                <span :class="[ltrMode ? 'ltr-font' : 'rtl-font']">{{ ltrMode ? item.join_time : item.persian_join_time }}</span>
              </template>
            </v-data-table>
          </v-stepper-content>

          <v-stepper-step v-if="!isMeet" step="4" complete @click="step = 4; getGroups()" complete-icon="mdi-cog-outline" :color="colors.success">
            {{ $t('meeting.groups') }}
          </v-stepper-step>
          <v-stepper-content step="4">
            <groups
                :groups="groups"
                :users="users"
                :clas="clas"
                :meetingId="meetingId"
                :currentUser="currentUser"
                :ltrMode="ltrMode"
                :colors="colors"
            ></groups>
          </v-stepper-content>

        </v-stepper>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Tasks from "./Tasks";
import Groups from "./Groups";

export default {
  name: "MeetingSetting",
  components: {Groups, Tasks},
  props: ['axios', 'meetingId', 'ltrMode', 'clas', 'echo', 'channel', 'currentUser', 'colors'],

  data() {
    return {
      dialog: false,
      step: 99,

      permissions: [],
      permissionsHeader: [
        {text: 'Name', value: 'user.name', align: 'center'},
        {text: 'Microphone', value: 'microphone', align: 'center'},
        {text: 'Webcam', value: 'webcam', align: 'center'},
        {text: 'Screen', value: 'screen', align: 'center'},
        {text: 'Whiteboard', value: 'whiteboard', align: 'center'},
        {text: 'Video File', value: 'video', align: 'center'},
        {text: 'Audio File', value: 'audio', align: 'center'},
        {text: 'Record', value: 'record', align: 'center'},
      ],

      attendances: [],
      attendancesHeader: [
        {text: 'Name', value: 'user.name', align: 'center'},
        {text: 'Is Attended', value: 'isAttended', align: 'center'},
        {text: 'Join Time', value: 'join_time', align: 'center'},
      ],

      users: [],
      tasks: [],
      groups: [],
    }
  },

  methods: {
    openSetting() {
      this.dialog = true;
      this.getMeetingInfo()

      if (!this.ltrMode) {
        this.permissionsHeader = [
          {text: 'نام', value: 'user.name', align: 'center'},
          {text: 'میکروفن', value: 'microphone', align: 'center'},
          {text: 'وب کم', value: 'webcam', align: 'center'},
          {text: 'صفحه نمایش', value: 'screen', align: 'center'},
          {text: 'وایت برد', value: 'whiteboard', align: 'center'},
          {text: 'فایل تصویری', value: 'video', align: 'center'},
          {text: 'فایل صوتی', value: 'audio', align: 'center'},
          {text: 'ضبط', value: 'record', align: 'center'},
        ]
        this.attendancesHeader = [
          {text: 'نام', value: 'user.name', align: 'center'},
          {text: 'حاضر', value: 'isAttended', align: 'center'},
          {text: 'زمان ورود', value: 'persian_join_time', align: 'center'},
        ]
      }
    },

    endMeeting() {
      axios.get((this.isMeet() ? '/user/meet/end-meeting/' : '/api/web-rtc/end-meeting/') + this.meetingId).then(() => {
        this.$emit('meeting-ended')
      })
    },

    getMeetingInfo(type = "all") { // all - permissions - attendances
      axios.get((this.isMeet() ? '/user/meet/info/' : '/api/web-rtc/meeting/info/') + this.meetingId + '?' + type).then((res) => {
        let responseData = res.data.data
        if (responseData.permissions) {
          this.permissions = responseData.permissions
        }
        if (responseData.attendances) {
          this.attendances = responseData.attendances
        }
      })
    },

    saveUserPermissions() {
      axios.post(this.isMeet() ? '/user/meet/permissions/update' : '/api/web-rtc/meeting/permissions/update', {
        permissions: this.permissions
      })
          .then((res) => {
            this.permissions = res.data.data.permissions
          })
          .catch((err) => {
            console.log(err)
          })
    },

    getUsers() {
      axios.get('/api/web-rtc/meeting/users/' + this.meetingId)
          .then((res) => {
            this.users = res.data.data
          })
          .catch((err) => {
            console.log(err)
          })
    },

    getTasks() {
      if (this.currentUser.isBroadcaster) {
        axios.get('/api/web-rtc/meeting/tasks/index/' + this.meetingId)
            .then((res) => {
              this.tasks = res.data.data
            })
            .catch((err) => {
              console.log(err)
            })
      } else {
        axios.get('/api/web-rtc/meeting/tasks/my/index/' + this.meetingId)
            .then((res) => {
              this.tasks = res.data.data
            })
            .catch((err) => {
              console.log(err)
            })
      }
    },

    getGroups() {
      axios.get('/api/web-rtc/meeting/groups/index/' + this.meetingId)
          .then((res) => {
            this.groups = res.data.data
          })
          .catch((err) => {
            console.log(err)
          })
    },

    isMeet() {
      return this.$route.name === 'Meet';
    }
  },

  mounted() {
    if (!this.isMeet()) {
      this.getUsers()
      this.echo.private(`tasks.${this.channel}`).listen(
          "TasksUpdated",
          ({data}) => {
            console.log("Task Updated");
            if (this.currentUser.isBroadcaster) {
              this.tasks = data.tasks
            } else {
              this.getTasks()
            }
          }
      );
      this.echo.private(`groups.${this.channel}`).listen(
          "GroupsUpdated",
          ({data}) => {
            console.log("Groups Updated");
            this.groups = data.groups
          }
      );
    }

    if (this.ltrMode) {
      let elements = document.getElementsByTagName('input');
      Object.entries(elements).forEach((elem) => {
        elem[1].style.cssText += ';direction:ltr!important;text-align:left!important;font-family:Arial!important';
      })
    }
    if (this.ltrMode) {
      let elements = document.getElementsByTagName('textarea');
      Object.entries(elements).forEach((elem) => {
        elem[1].style.cssText += ';direction:ltr!important;text-align:left!important;font-family:Arial!important';
      })
    }
  }
}
</script>

<style scoped>
.endMeetingBtn {
  box-shadow: none !important;
  position: fixed !important;
  bottom: 20px !important;
  left: 20px !important;
}

.openSettingBtn {
  box-shadow: none !important;
  position: fixed !important;
  bottom: 20px !important;
}

.bordered-table {
  padding: 10px;
  border-radius: 15px;
  border: 2px solid black;
  box-shadow: none !important;
}

.v-btn {
  box-shadow: none !important;
}

.v-stepper__step {
  cursor: pointer !important;
}
</style>
<template>
  <div>
    <v-btn v-if="!chatModal" fab :color="colors.success" @click="getComments()" class="commentBtn"
           :style="{right: getCommentBtnRight()}">
      <v-icon>mdi-comment</v-icon>
    </v-btn>
    <div class="chat-modal" :style="{display: chatModal ? '' : 'none'}">
      <div class="chat-header" :class="[ltrMode ? 'ltr-font' : 'rtl-font']">
        <span class="chat-header-title">{{ $t('meeting.comments') }}</span>
        <v-btn fab text @click="chatModal = false" small class="chat-header-close-btn">
          <v-icon :color="colors.danger">mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="chat-content">
        <div v-for="comment in comments"
             :key="comment.id"
             :class="[currentUser.id === comment.user.id ? 'message-self-div' : 'message-div']">
          <div :class="[currentUser.id === comment.user.id ? 'message-self' : 'message']"
               :style="{
            backgroundColor: currentUser.id === comment.user.id ? colors.success : '#aba6a6',
            color: currentUser.id === comment.user.id ? textColor : '#000',
          }">
            <div class="message-sender" v-if="!comment.file" :class="[ltrMode ? 'ltr-font' : 'rtl-font']">
              {{ comment.user.name }}:
            </div>
            <div class="message-content" v-if="comment.file" :class="[ltrMode ? 'ltr-font' : 'rtl-font']">
              <a :href="comment.file" target="_blank" :style="{color: currentUser.id === comment.user.id ? textColor : '#000'}">
                {{ comment.user.name }} {{ $t('meeting.shared-a-file') }}
              </a>
            </div>
            <div class="message-content" v-else :class="[ltrMode ? 'ltr-font' : 'rtl-font']">{{ comment.comment }}</div>
          </div>
          <div class="trash-div">
            <v-btn x-small fab :color="colors.danger" v-if="isBroadcaster" @click="deleteComment(comment.id)">
              <v-icon color="white">mdi-trash-can-outline</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="chat-input">
        <v-text-field
            v-model="newComment"
            :prepend-icon="ltrMode ? 'mdi-paperclip' : 'mdi-send'"
            :append-outer-icon="ltrMode ? 'mdi-send' : 'mdi-paperclip'"
            filled
            clear-icon="mdi-close-circle"
            clearable
            :label="$t('meeting.type_something')"
            type="text"
            @keyup.enter="sendComment()"
            @click:append-outer="ltrMode ? sendComment() : selectFile()"
            @click:prepend="ltrMode ? selectFile() : sendComment()"
            @click:clear="clearInput"
        ></v-text-field>
        <input @change="fileSelected" type="file" id="file-input" style="display: none">
        <div class="scroll-on-x">
          <v-chip :class="ltrMode ? 'ml-1' : 'mr-1'"
                  v-for="quickMessage in quickMessages"
                  @click="newComment = quickMessage"
                  :key="quickMessage">
            {{ quickMessage }}
          </v-chip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MeetingComment",

  props: [
    'currentUser', 'channelName', 'axios', 'echo', 'isBroadcaster', 'meetingId', 'ltrMode', 'colors', 'textColor'
  ],

  data() {
    return {
      chatModal: false,
      newComment: null,

      comments: [],

      quickMessages: [
        'Be Right Back',
        'Back',
        'Hello',
        'bye',
        'Unstable Network',
        "Can't share audio",
        "Can't share video"
      ]
    }
  },

  methods: {
    sendComment() {
      let data = new FormData()
      data.append('meeting_id', this.meetingId)
      if (document.getElementById('file-input').files.length) {
        data.append('file', document.getElementById('file-input').files[0])
      } else {
        data.append('comment', this.newComment)
      }

      this.axios.post(this.isMeet() ? '/user/meet/comment/new' : '/api/web-rtc/comment/new', data)
          .then((res) => {
            console.log(res)
            this.newComment = null
          })
          .catch((err) => {
            console.log(err)
          })
    },

    selectFile() {
      let elem = document.getElementById('file-input');
      let evt = document.createEvent("MouseEvents");
      evt.initEvent("click", true, false);
      elem.dispatchEvent(evt);
    },

    fileSelected() {
      let file = document.getElementById('file-input').files[0]
      this.newComment = file.name
    },

    clearInput() {
      this.newComment = null
      document.getElementById('file-input').value = null
    },

    getComments() {
      this.axios.get((this.isMeet() ? '/user/meet/comments/' : '/api/web-rtc/comments/') + this.meetingId)
          .then((res) => {
            this.chatModal = true
            this.comments = res.data.data
            this.$nextTick(() => {
              this.scrollCommentToBottom()
            })
          })
          .catch((err) => {
            console.log(err)
          })
    },

    scrollCommentToBottom() {
      let objDiv = document.getElementsByClassName("chat-content")[0];
      objDiv.scrollTop = objDiv.scrollHeight;
    },

    deleteComment(commentID) {
      this.axios.get((this.isMeet() ? '/user/meet/comments/delete/' : '/api/web-rtc/comments/delete/') + commentID)
          .then(() => {
          })
          .catch((err) => {
            console.log(err)
          })
    },

    isMeet() {
      return this.$route.name === 'Meet';
    },

    getCommentBtnRight() {
      if (this.isMeet()) {
        if (!this.mobile) {
          return '70px!important';
        }
      }
      return '20px!important';
    }
  },

  mounted() {
    this.textColor = this.textColor ?? '#000';

    /* LISTEN FOR SIGNAL OFFER FROM BROADCASTERS */
    this.echo.private(`comment.${this.channelName}`).listen(
        "NewComment",
        ({data}) => {
          console.log("New Comment");
          this.comments.push(data)
          this.$nextTick(() => {
            this.scrollCommentToBottom()
          })
        }
    );

    /* LISTEN FOR SIGNAL OFFER FROM BROADCASTERS */
    this.echo.private(`comment.${this.channelName}`).listen(
        "DeleteComment",
        ({data}) => {
          console.log("Delete Comment");
          const index = this.comments.findIndex(
              (item) => item.id === data.id
          );
          this.comments.splice(index, 1);
        }
    );

    if (this.ltrMode) {
      let elements = document.getElementsByTagName('input');
      Object.entries(elements).forEach((elem) => {
        elem[1].style.cssText += ';direction:ltr!important;text-align:left!important;font-family:Arial!important';
      })
    }
  }
}
</script>

<style scoped>
.commentBtn {
  box-shadow: none !important;
  position: fixed !important;
  bottom: 20px !important;
  color: white !important;
}

.chat-modal {
  background-color: whitesmoke;
  z-index: 9999;
  max-width: 500px;
  width: 95%;
  min-height: 350px;
  height: 500px;
  min-width: 270px;
  position: fixed;
  bottom: 20px;
  right: 10px;
  border: 2px solid black;
  padding: 15px;
  border-radius: 20px;
}

.chat-content {
  overflow-y: scroll;
  min-height: 290px;
  height: 300px;
}

.scroll-on-x {
  overflow-x: scroll;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
}

.chat-header-title {
  font-weight: bold;
}

.chat-header-close-btn {
  margin-top: -10px;
}

.message-self {
  border-radius: 20px;
  padding: 10px;
  max-width: 450px;
  width: 250px;
  margin-top: 5px;
}

.message-div {
  width: 100%;
  direction: ltr;
  display: inline-flex;
}

.message-self-div {
  width: 100%;
  display: inline-flex;
  direction: rtl;
}

.message {
  border-radius: 20px;
  padding: 10px;
  max-width: 450px;
  width: 250px;
  margin-top: 5px;
}

.chat-input {
  z-index: 999999;
  position: absolute;
  width: 100%;
  right: 0;
  padding: 20px 20px 0;
  bottom: 0;
  white-space: nowrap;
}

.trash-div {
  align-self: center;
  margin-right: 5px;
  margin-left: 5px;
}

.message-content {
  overflow-wrap: anywhere;
}
</style>
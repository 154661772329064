var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.currentUser.isBroadcaster)?_c('v-btn',{staticClass:"endMeetingBtn white--text",attrs:{"fab":"","rounded":"","color":_vm.colors.danger},on:{"click":_vm.endMeeting}},[_c('v-icon',[_vm._v("mdi-exit-to-app")])],1):_vm._e(),((!_vm.isMeet) || (_vm.isMeet && _vm.currentUser.isBroadcaster))?_c('v-btn',{staticClass:"openSettingBtn white--text",style:({left: _vm.currentUser.isBroadcaster ? '85px' : '20px'}),attrs:{"fab":"","rounded":"","color":_vm.colors.success},on:{"click":_vm.openSetting}},[_c('v-icon',[_vm._v("mdi-cog")])],1):_vm._e(),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":_vm.colors.success}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',{class:[_vm.ltrMode ? 'ltr-font' : 'rtl-font']},[_vm._v(_vm._s(_vm.$t('meeting.setting')))])],1),_c('v-stepper',{class:[_vm.ltrMode ? 'ltr-font' : 'rtl-font'],attrs:{"vertical":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[(_vm.currentUser.isBroadcaster)?_c('v-stepper-step',{attrs:{"step":"1","complete":"","complete-icon":"mdi-cog-outline","color":_vm.colors.success},on:{"click":function($event){_vm.step = 1}}},[_vm._v(" "+_vm._s(_vm.$t('meeting.manage-users-title'))+" "),_c('small',[_vm._v(_vm._s(_vm.$t('meeting.manage-users-description')))])]):_vm._e(),_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-data-table',{staticClass:"elevation-5 bordered-table",attrs:{"align":"center","headers":_vm.permissionsHeader,"items":_vm.permissions,"items-per-page":15,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.microphone",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"white--text",attrs:{"color":item.microphone ? _vm.colors.success : _vm.colors.danger,"x-small":"","fab":""},on:{"click":function($event){item.microphone = !item.microphone}}},[(item.microphone)?_c('v-icon',[_vm._v("mdi-check")]):_c('v-icon',[_vm._v("mdi-close")])],1)]}},{key:"item.webcam",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"white--text",attrs:{"color":item.webcam ? _vm.colors.success : _vm.colors.danger,"x-small":"","fab":""},on:{"click":function($event){item.webcam = !item.webcam}}},[(item.webcam)?_c('v-icon',[_vm._v("mdi-check")]):_c('v-icon',[_vm._v("mdi-close")])],1)]}},{key:"item.screen",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"white--text",attrs:{"color":item.screen ? _vm.colors.success : _vm.colors.danger,"x-small":"","fab":""},on:{"click":function($event){item.screen = !item.screen}}},[(item.screen)?_c('v-icon',[_vm._v("mdi-check")]):_c('v-icon',[_vm._v("mdi-close")])],1)]}},{key:"item.whiteboard",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"white--text",attrs:{"color":item.whiteboard ? _vm.colors.success : _vm.colors.danger,"x-small":"","fab":""},on:{"click":function($event){item.whiteboard = !item.whiteboard}}},[(item.whiteboard)?_c('v-icon',[_vm._v("mdi-check")]):_c('v-icon',[_vm._v("mdi-close")])],1)]}},{key:"item.video",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"white--text",attrs:{"color":item.video ? _vm.colors.success : _vm.colors.danger,"x-small":"","fab":""},on:{"click":function($event){item.video = !item.video}}},[(item.video)?_c('v-icon',[_vm._v("mdi-check")]):_c('v-icon',[_vm._v("mdi-close")])],1)]}},{key:"item.audio",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"white--text",attrs:{"color":item.audio ? _vm.colors.success : _vm.colors.danger,"x-small":"","fab":""},on:{"click":function($event){item.audio = !item.audio}}},[(item.audio)?_c('v-icon',[_vm._v("mdi-check")]):_c('v-icon',[_vm._v("mdi-close")])],1)]}},{key:"item.record",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"white--text",attrs:{"color":item.record ? _vm.colors.success : _vm.colors.danger,"x-small":"","fab":""},on:{"click":function($event){item.record = !item.record}}},[(item.record)?_c('v-icon',[_vm._v("mdi-check")]):_c('v-icon',[_vm._v("mdi-close")])],1)]}}])}),_c('v-btn',{staticClass:"white--text mb-4 mt-4",attrs:{"color":_vm.colors.success,"rounded":""},on:{"click":_vm.saveUserPermissions}},[_vm._v(" "+_vm._s(_vm.$t('meeting.save'))+" ")]),_c('v-btn',{staticClass:"white--text mb-4 mt-4",class:[_vm.ltrMode ? 'ml-2' : 'mr-2'],attrs:{"color":_vm.colors.danger,"rounded":""},on:{"click":function($event){return _vm.getMeetingInfo('permissions')}}},[_vm._v(_vm._s(_vm.$t('meeting.reset'))+" ")])],1),(!_vm.isMeet)?_c('v-stepper-step',{attrs:{"color":_vm.colors.success,"step":"2","complete":"","complete-icon":"mdi-cog-outline"},on:{"click":function($event){_vm.step = 2; _vm.getTasks()}}},[_vm._v(" "+_vm._s(_vm.$t('meeting.tasks'))+" ")]):_vm._e(),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('tasks',{attrs:{"ltrMode":_vm.ltrMode,"tasks":_vm.tasks,"users":_vm.users,"clas":_vm.clas,"meetingId":_vm.meetingId,"currentUser":_vm.currentUser,"colors":_vm.colors}})],1),(!_vm.isMeet && _vm.currentUser.isBroadcaster)?_c('v-stepper-step',{attrs:{"step":"3","complete":"","complete-icon":"mdi-cog-outline","color":_vm.colors.success},on:{"click":function($event){_vm.step = 3}}},[_vm._v(" "+_vm._s(_vm.$t('meeting.users-attendances'))+" ")]):_vm._e(),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('v-data-table',{staticClass:"elevation-5 bordered-table",attrs:{"align":"center","headers":_vm.attendancesHeader,"items":_vm.attendances,"items-per-page":15,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.isAttended",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"white--text",attrs:{"color":item.persian_join_time ? _vm.colors.success : _vm.colors.danger,"x-small":"","fab":""}},[(item.persian_join_time)?_c('v-icon',[_vm._v("mdi-check")]):_c('v-icon',[_vm._v("mdi-close")])],1)]}},{key:"item.join_time",fn:function(ref){
var item = ref.item;
return [_c('span',{class:[_vm.ltrMode ? 'ltr-font' : 'rtl-font']},[_vm._v(_vm._s(_vm.ltrMode ? item.join_time : item.persian_join_time))])]}}])})],1),(!_vm.isMeet)?_c('v-stepper-step',{attrs:{"step":"4","complete":"","complete-icon":"mdi-cog-outline","color":_vm.colors.success},on:{"click":function($event){_vm.step = 4; _vm.getGroups()}}},[_vm._v(" "+_vm._s(_vm.$t('meeting.groups'))+" ")]):_vm._e(),_c('v-stepper-content',{attrs:{"step":"4"}},[_c('groups',{attrs:{"groups":_vm.groups,"users":_vm.users,"clas":_vm.clas,"meetingId":_vm.meetingId,"currentUser":_vm.currentUser,"ltrMode":_vm.ltrMode,"colors":_vm.colors}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <!-- ASSIGN TASKS -->
    <v-row class="mb-16" v-if="currentUser.isBroadcaster">
      <v-col cols="12" lg="6" md="6" sm="12">
        <v-text-field hide-details :label="$t('meeting.title')" v-model="task.title"></v-text-field>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="12">
        <v-select :items="users" item-value="id" item-text="name" hide-details :label="$t('meeting.assignees')" multiple
                  v-model="task.users"></v-select>
      </v-col>
      <v-col cols="12">
        <v-textarea outlined hide-details :label="$t('meeting.description')" v-model="task.description"></v-textarea>
      </v-col>
      <v-col cols="12" style="align-content: center; text-align: center; padding-top: 30px;">
        <v-btn rounded :color="colors.success" class="white--text" v-if="selectedTask" @click="updateTask">{{ $t('meeting.update') }}</v-btn>
        <v-btn rounded :color="colors.warning" class="white--text ml-2" v-if="selectedTask" @click="cancelEditTask">{{ $t('meeting.cancel') }}</v-btn>
        <v-btn rounded :color="colors.success" class="white--text" v-else @click="assignTask">{{ $t('meeting.assign') }}</v-btn>
      </v-col>
    </v-row>

    <!-- INDEX OF TASKS -->
    <v-expansion-panels multiple class="mb-4 pa-2">
      <v-expansion-panel v-for="task in tasks" :key="task.id">
        <v-expansion-panel-header>
          <v-row no-gutters>
            <v-col cols="4" style="align-self: center" :class="[ltrMode ? 'ltr-font' : 'rtl-font']">
              <v-icon v-if="task.status === 'done'" color="green">mdi-check</v-icon>
              {{ task.title }}
            </v-col>
            <v-col class="text--secondary" style="align-self: center" :class="[ltrMode ? 'ltr-font' : 'rtl-font']">
              Assignees :
              <span v-for="user in task.users" :key="user.id">{{ user.name }}, </span>
            </v-col>
            <v-col cols="2" style="text-align: center">
              <v-btn small rounded v-if="currentUser.isBroadcaster" :color="colors.warning" class="white--text mx-1" @click="editTask(task)">{{ $t('meeting.edit') }}</v-btn>
              <v-btn small rounded v-if="currentUser.isBroadcaster" :color="colors.danger" class="white--text mx-1" @click="deleteTask(task)">{{ $t('meeting.delete') }}</v-btn>
              <v-btn small rounded v-if="task.status === 'in-progress'" :color="colors.success" class="white--text mx-1" @click="changeStatus(task, 'done')">{{ $t('meeting.done') }}</v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content style="text-align: left">
          {{ task.description }}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Tasks",

  props: ['ltrMode', 'tasks', 'users', 'clas', 'meetingId', 'currentUser', 'colors'],

  data() {
    return {
      task: {
        title: null,
        description: null,
        users: [],
      },
      selectedTask: null,
    }
  },

  methods: {
    editTask(task) {
      this.selectedTask = task
      this.task.title = task.title
      this.task.description = task.description
      this.task.users = task.users
    },

    assignTask() {
      axios.post('/api/web-rtc/meeting/tasks/create/' + this.meetingId, {
        title: this.task.title,
        description: this.task.description,
        users: this.task.users
      })
          .then(() => {})
          .catch((err) => {
            console.log(err)
          })
    },

    updateTask() {
      this.task.users.forEach((item, index) => {
        if (!Number.isInteger(item)) {
          this.task.users[index] = item.id
        }
      })

      axios.post('/api/web-rtc/meeting/tasks/update/' + this.selectedTask.id, {
        title: this.task.title,
        description: this.task.description,
        meeting_id: this.meetingId,
        users: this.task.users
      })
          .then(() => {})
          .catch((err) => {
            console.log(err)
          })
    },

    cancelEditTask() {
      this.selectedTask = null
      this.task.title = null
      this.task.description = null
      this.task.users = []
    },

    deleteTask(task) {
      axios.get('/api/web-rtc/meeting/tasks/delete/' + task.id + '/' + this.meetingId)
          .then(() => {})
          .catch((err) => {
            console.log(err)
          })
    },

    changeStatus(task, status) {
      axios.post('/api/web-rtc/meeting/tasks/change-status/' + task.id, {
        status,
        meeting_id: this.meetingId,
      })
          .then(() => {})
          .catch((err) => {
            console.log(err)
          })
    },
  }
}
</script>

<style scoped>
.v-btn {
  box-shadow: none!important;
}
</style>
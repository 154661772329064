<template>
  <div>
    <!-- RANDOM GROUP DIALOG -->
    <v-dialog v-model="randomDialog">
      <v-card min-width="320px">
        <v-card-title class="justify-center">{{ $t('meeting.create_random_group') }}</v-card-title>
        <v-card-text>
          <v-text-field v-model="randomChunkSize" :label="$t('meeting.random_chunk_size')"></v-text-field>
        </v-card-text>
        <v-card-actions style="justify-content: center!important;">
          <v-btn rounded width="100px" :color="colors.success" class="white--text" @click="createRandomGroup">
            {{ $t('meeting.generate') }}
          </v-btn>
          <v-btn rounded width="100px" :color="colors.danger" class="white--text" @click="randomDialog = false">
            {{ $t('meeting.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- CREATE GROUP -->
    <v-row v-if="currentUser.isBroadcaster">
      <v-col cols="12" lg="3" md="4" sm="12">
        <v-text-field hide-details :label="$t('meeting.title')" v-model="group.name"></v-text-field>
      </v-col>
      <v-col cols="12" lg="7" md="5" sm="12">
        <v-select :items="users" item-value="id" item-text="name" hide-details :label="$t('meeting.members')" multiple
                  v-model="group.users"></v-select>
      </v-col>
      <v-col cols="12" lg="2" md="3" sm="12" style="align-content: center; text-align: center; padding-top: 30px;">
        <v-btn rounded :color="colors.success" class="white--text" v-if="selectedGroup"
               @click="updateGroup">
          {{ $t('meeting.update') }}
        </v-btn>
        <v-btn rounded :color="colors.warning" class="white--text ml-2" v-if="selectedGroup"
               @click="cancelEditGroup">
          {{ $t('meeting.cancel') }}
        </v-btn>
        <v-btn rounded :color="colors.success" class="white--text mr-1 ml-1" v-if="!selectedGroup" @click="createGroup">
          {{ $t('meeting.assign') }}
        </v-btn>
        <v-btn rounded :color="colors.warning" class="white--text mr-1 ml-1" v-if="!selectedGroup" @click="openRandomDialog">
          {{ $t('meeting.random') }}
        </v-btn>
      </v-col>
    </v-row>

    <!-- INDEX OF GROUPS -->
    <v-row>
      <v-col cols="12" class="pa-6">
        <v-data-table
            align="center"
            :headers="groupsHeader"
            :items="groups"
            class="elevation-5 bordered-table"
            :items-per-page="15"
            hide-default-footer
        >
          <template v-slot:item.users="{item}">
            <span v-for="user in item.users" :key="user.id">{{ user.name }}, </span>
          </template>
          <template v-slot:item.actions="{item}">
            <v-btn v-if="currentUser.isBroadcaster" rounded :color="colors.warning" class="white--text mr-1 ml-1"
                   @click="editGroup(item)">
              {{ $t('meeting.edit') }}
            </v-btn>
            <v-btn v-if="currentUser.isBroadcaster" rounded :color="colors.danger" class="white--text mr-1 ml-1"
                   @click="deleteGroup(item)">
              {{ $t('meeting.delete') }}
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Groups",

  props: ['groups', 'users', 'clas', 'meetingId', 'currentUser', 'ltrMode', 'colors'],

  data() {
    return {
      group: {
        name: null,
        users: [],
      },
      selectedGroup: null,
      randomDialog: false,
      randomChunkSize: 2,

      groupsHeader: [
        {text: 'Name', value: 'name', align: 'center'},
        {text: 'Members', value: 'users', align: 'center'},
      ],
    }
  },

  mounted() {
    if (!this.ltrMode) {
      this.groupsHeader = [
        {text: 'نام', value: 'name', align: 'center'},
        {text: 'اعضا', value: 'users', align: 'center'},
      ]
      if (this.currentUser.isBroadcaster) {
        this.groupsHeader.push(
            {text: 'عملیات', value: 'actions', align: 'center'},
        )
      }
    } else {
      if (this.currentUser.isBroadcaster) {
        this.groupsHeader.push(
            {text: 'Actions', value: 'actions', align: 'center'},
        )
      }
    }
  },

  methods: {
    editGroup(group) {
      this.selectedGroup = group
      this.group.name = group.name
      this.group.users = group.users
    },

    createGroup() {
      axios.post('/api/web-rtc/meeting/groups/create/' + this.meetingId, {
        name: this.group.name,
        users: this.group.users
      })
          .then(() => {
          })
          .catch((err) => {
            console.log(err)
          })
    },

    updateGroup() {
      this.group.users.forEach((item, index) => {
        if (!Number.isInteger(item)) {
          this.group.users[index] = item.id
        }
      })

      axios.post('/api/web-rtc/meeting/groups/update/' + this.selectedGroup.id, {
        name: this.group.name,
        meeting_id: this.meetingId,
        users: this.group.users
      })
          .then(() => {
          })
          .catch((err) => {
            console.log(err)
          })
    },

    cancelEditGroup() {
      this.selectedGroup = null
      this.group.name = null
      this.group.users = []
    },

    deleteGroup(group) {
      axios.get('/api/web-rtc/meeting/groups/delete/' + group.id + '/' + this.meetingId)
          .then(() => {
          })
          .catch((err) => {
            console.log(err)
          })
    },

    createRandomGroup() {
      this.randomDialog = false;

      let otherUserIds = []
      this.users.forEach((item) => {
        if (item.id !== this.currentUser.id) {
          otherUserIds.push(item.id)
        }
      })

      Array.from({length: Math.ceil(otherUserIds.length / this.randomChunkSize)}, (val, i) => {
        let users = otherUserIds.slice(i * this.randomChunkSize, i * this.randomChunkSize + this.randomChunkSize)
        axios.post('/api/web-rtc/meeting/groups/create/' + this.meetingId, {
          name: 'Group ' + (i + 1),
          users: users
        }).then(() => {
        }).catch((err) => {
          console.log(err)
        })
      })
    },

    openRandomDialog() {
      this.randomDialog = true
      this.$nextTick(() => {
        if (this.ltrMode) {
          let elements = document.getElementsByTagName('input');

          Object.entries(elements).forEach((elem) => {
            elem[1].style.cssText += ';direction:ltr!important;text-align:left!important;font-family:Arial!important';
          })
        }
      })
    }
  },
}
</script>

<style scoped>
.v-btn {
  box-shadow: none !important;
}

.bordered-table {
  padding: 10px;
  border-radius: 15px;
  border: 2px solid black;
  box-shadow: none !important;
}
</style>